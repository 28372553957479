<template>
  <base-layout page-default-back-link="/tabs/store" title="StockPage">
    <ion-list>
          <InOutStock 
              v-for="inventory in stockItems" 
              :key="inventory.name"
              :stock="inventory"
              >
          </InOutStock>
      </ion-list>
  </base-layout>
</template>

<script>
//import { IonList } from '@ionic/vue';
import InOutStock from '../components/InOutStock.vue';

export default  {
  name: 'StockPage',
  components: { InOutStock},
  data() {
    return {
      
    };
  },
  computed: {
    stockItems() {
      return this.$store.getters.stockItems;
    },
    orders() {
      return this.$store.getters.orders;
    }
  },
  mounted() {
    if (this.stockItems == null) {
      this.$store.dispatch('loadStock');
    }
  }  
}
</script>