<template>
    <ion-item>
            <ion-thumbnail slot="start">
                
                <img :style="'background-repeat: no-repeat; background-size: 55px 55px; background-image: url(/assets/' + stock.name.replace(' ', '').toLowerCase() + '.png)'" v-if="stock.inStock == false" src="/assets/x.png"/>
                <img :src="'/assets/' + stock.name.replace(' ', '').toLowerCase() + '.png'" v-if="stock.inStock == true"/>
            </ion-thumbnail>
            <ion-label>
                {{stock.name}}
            </ion-label>
            <ion-button @click="markOutOfStock(stock.name)" v-if="stock.inStock" slot="end">Out Of Stock</ion-button>
            <ion-button @click="markInStock(stock.name)" v-if="stock.inStock == false" slot="end">In Stock</ion-button>
        </ion-item>
</template>

<script>
//import { IonItem, IonThumbnail, IonImg, IonLabel, IonButton } from '@ionic/vue';

export default {
    props: ["stock"],
    components: {

    },
    methods: {
        markOutOfStock(stockName) {
            var data = {};
            data[stockName] = false;
            //data = { Bacon: false }; 
            this.$store.dispatch('markStock', data); //{stockName: stockName, inStock: false});
        },
        markInStock(stockName) {
            var data = {};
            data[stockName] = true;
            this.$store.dispatch('markStock', data); //{stockName: stockName, inStock: true});
        }
    }
}
</script>